// Component used to show different types of loading icons

import { Spinner, SpinnerProps } from "react-bootstrap";

const LoadingSpinner: React.FC<SpinnerProps> = (props) => {
  return (
    <div className="center">
      <Spinner {...props} variant="primary" />
    </div>
  );
};

export { LoadingSpinner };
