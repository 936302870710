// Page for parents to view their child's weekly schedule for the camp
// Parents can register their child for specific weeks and go to checkout

import React from "react";
import { Button, Container, Form, Table, Spinner, Col, Row } from "react-bootstrap";
import "./HouseholdForm.css";
import { useHistory } from "react-router-dom";
import { dateTimeToTime, dateTimeToDate } from "./utils/DateTimeUtil";
import { filterAndSortWeeksCurrentYear } from "./utils/FilterAndSortUtil";
import { gradeToString } from "./utils/DataToStringUtil";
import { areGroupsFull } from "./utils/GroupUtil";
import { Camp_Week, Camper, Registered_Camper_Week } from "./models/models";
import axios from "axios";
import { getAuth } from "firebase/auth";

interface Camp_WeekWithStatus extends Camp_Week {
  status: string;
}

export default function CamperScheduling() {
  const auth = getAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isRegistrationClosed, setIsRegistrationClosed] = React.useState(false);
  const [camper, setCamper] = React.useState<Camper>();
  const [campWeeks, setCampWeeks] = React.useState<Camp_WeekWithStatus[]>([]);
  const [numShirts, setNumShirts] = React.useState(0);
  const [shirtPrice, setShirtPrice] = React.useState(0);
  const [weeksRegistered, setWeeksRegistered] = React.useState<number[]>([]);
  const [registeredCamperWeeks, setRegisteredCamperWeeks] = React.useState<Registered_Camper_Week[]>([]);
  const [weeksSelected, setWeeksSelected] = React.useState<number[]>([]);
  const [earlyCutOffDate, setEarlyCutOffDate] = React.useState("");
  const [checkoutDisabled, setCheckoutDisabled] = React.useState(true);
  const [isEarlyBird, setIsEarlyBird] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);
    setIsRegistrationClosed(new Date() < new Date("2024-01-01")); // TODO: add feature to let admin open/close registration and select date
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        await axios.get(process.env.REACT_APP_API + "api/users/getUser/" + user.uid).then((res) => {
          if (res.data.role === "admin") {
            setIsAdmin(true);
          }
        });
      }
    });
    (async () => {
      let grade = 0;
      await axios
        .get(process.env.REACT_APP_API + "api/campers/getCamper/" + sessionStorage.getItem("camper_id"))
        .then(async (response) => {
          setCamper(response.data);
          grade = response.data.grade;
          await axios
            .get(process.env.REACT_APP_API + "api/shirts/getShirtByShirtNameAndSize/generic/" + response.data.shirtSize)
            .then((response) => {
              setShirtPrice(response.data.price);
            });
          // console.log(response.data);
        });
      await axios.get(process.env.REACT_APP_API + "api/camp_weeks/getCamp_Weeks").then(async (response) => {
        const weeks = filterAndSortWeeksCurrentYear(response.data) as Camp_WeekWithStatus[];
        for (let week of weeks) {
          week.status = (await areGroupsFull(grade, week.id)) ? "Waitlist" : "Open";
        }
        setCampWeeks(weeks);
        if (weeks.length > 0) {
          const earlyCutOffDateStr = new Date(weeks[0].earlyCutOff.substring(0, weeks[0].earlyCutOff.length - 3))
            .toDateString()
            .substring(4, 15);
          setEarlyCutOffDate(earlyCutOffDateStr);
          const earlyCutOff = new Date(earlyCutOffDateStr);
          earlyCutOff.setDate(earlyCutOff.getDate() + 1);
          if (new Date() < earlyCutOff) {
            setIsEarlyBird(true);
          }
        }
        // console.log(response.data);
      });
      await axios
        .get(
          process.env.REACT_APP_API +
            "api/registered_camper_weeks/getRegistered_Camper_WeekByCamperID/" +
            sessionStorage.getItem("camper_id")
        )
        .then((response) => {
          setRegisteredCamperWeeks(response.data);
          // console.log(response.data);
          setWeeksRegistered(response.data.map((week: { camp_week_id: number }) => week.camp_week_id));
        });
      setIsLoading(false);
    })();
    return unsubscribe;
  }, [auth]);

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (checkoutDisabled) {
      alert(`Please check the box at the bottom to confirm ${camper?.firstName} ${camper?.lastName}'s grade`);
      return;
    }
    if (weeksSelected.length === 0 && numShirts === 0) {
      alert("Please select at least one week to register for or order at least one shirt.");
      return;
    }
    sessionStorage.setItem("weeksSelected", weeksSelected.join(","));
    sessionStorage.setItem("numShirts", numShirts.toString());
    history.push("/parent/checkout");
  };

  const handleChange = (weekId: number) => (e: { target: { value: any } }) => {
    if (weeksSelected.includes(weekId)) {
      setWeeksSelected(weeksSelected.filter((week) => week !== weekId));
    } else {
      setWeeksSelected([...weeksSelected, weekId]);
    }
  };

  const handleUnregister = async (week: Camp_Week) => {
    // Ask for confirmation
    if (!window.confirm("Are you sure you want to unregister from this week?")) {
      return;
    }
    setIsLoading(true);
    // give parent credit for unregister week
    let refundCreditAmt = 0;
    // find when parent paid from payment informations table using user id and registered camp week id
    for (let registeredCamperWeek of registeredCamperWeeks) {
      if (registeredCamperWeek.camp_week_id === week.id) {
        const paymentInformation = (
          await axios.get(
            `${process.env.REACT_APP_API}/api/payment_informations/getPayment_InformationByUserIDAndRegisteredCamperWeekID/${camper?.parent_id}/${registeredCamperWeek.id}`
          )
        ).data;
        // Check if camper is in a group
        if (!registeredCamperWeek.group_id) {
          alert("Email info@campizza.com to assign this camper to a group. Then you will be able to unregister.");
          return;
        }
        const group = (
          await axios.get(`${process.env.REACT_APP_API}/api/groups/getGroup/${registeredCamperWeek.group_id}`)
        ).data;
        // Set refund cutoff date to be on midnight the day after the start of the camp week
        let refundCutoffDate = new Date(week.start);
        refundCutoffDate.setDate(refundCutoffDate.getDate() + 2);
        refundCutoffDate.setHours(0, 0, 0, 0);
        // Only refunds credit if the week has not started or if camper is in waitlist group
        if (new Date() < refundCutoffDate || group.name.startsWith("Waitlist")) {
          if (new Date(paymentInformation.transactionTime) < new Date(earlyCutOffDate)) {
            // console.log("before cutoff");
            refundCreditAmt = week.earlyCost;
          } else {
            // console.log("after cutoff");
            refundCreditAmt = week.regularCost;
          }
        }
        break;
      }
    }
    // Update parent's credit
    await axios.get(process.env.REACT_APP_API + "api/parents/getParent/" + camper?.parent_id).then(async (res) => {
      await axios.put(process.env.REACT_APP_API + "api/parents/updateParent/" + camper?.parent_id, {
        ...res.data,
        credit: res.data.credit + refundCreditAmt,
      });
    });

    // delete registered camper week
    await axios.delete(
      `${process.env.REACT_APP_API}/api/registered_camper_weeks/deleteRegistered_Camper_WeekWithCamperIdAndCampWeekId/${
        week.id
      }/${sessionStorage.getItem("camper_id")}`
    );
    history.go(0);
  };

  const handlePrice = (week: Camp_Week) => {
    let price = 0;
    if (isEarlyBird) {
      price = week.earlyCost;
    } else {
      price = week.regularCost;
    }
    return price;
  };

  const handleBack = () => {
    sessionStorage.removeItem("weeksSelected");
    sessionStorage.removeItem("numShirts");
    history.goBack();
  };

  return (
    <div className="Schedule">
      {isLoading && (
        <Container className="Schedule-Table center">
          <Spinner animation="border" variant="primary" />
        </Container>
      )}
      {!isLoading && campWeeks.length === 0 && (
        <Container className="Schedule-Table">
          <Button variant="primary" className="backButton" onClick={handleBack}>
            Back
          </Button>
          <p>Schedule TBD. Camp weeks for this summer have not been created yet.</p>
        </Container>
      )}
      {!isLoading && campWeeks.length !== 0 && (
        <Container className="Schedule-Table" style={{ width: "95%" }}>
          <br />
          <Button variant="primary" className="backButton" onClick={handleBack}>
            Back
          </Button>
          <br />
          <br />
          <h3>Camper Registration</h3>
          {!isAdmin && isRegistrationClosed && (
            <h3>
              <b>Registration will open on Feb. 1</b>
            </h3>
          )}
          <br />
          <p>
            Camper Name: <u>{camper ? camper?.firstName + " " + camper?.lastName : ""}</u>
          </p>
          <br />
          <p>
            Camp Times:{" "}
            <u>{campWeeks && `${dateTimeToTime(campWeeks[0].start)} - ${dateTimeToTime(campWeeks[0].end)}`}</u>
          </p>
          <br />
          {isEarlyBird && (
            <p>
              Early Bird (Register by {earlyCutOffDate}): <u>${campWeeks ? campWeeks[0].earlyCost : ""}/week</u>
            </p>
          )}
          <br />
          {window.innerWidth < 600 && <p>Swipe across the table to view more</p>}
          <Form>
            <Table striped bordered responsive className="schedule">
              <thead>
                <tr>
                  <th style={{ minWidth: 90 }}>Week</th>
                  <th style={{ minWidth: 100 }}>Start</th>
                  <th style={{ minWidth: 100 }}>End</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th style={{ minWidth: 200 }}>Registration Status</th>
                </tr>
              </thead>
              <tbody>
                {campWeeks.length !== 0 ? (
                  campWeeks.map((item) => (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>{dateTimeToDate(item.start)}</td>
                      <td>{dateTimeToDate(item.end)}</td>
                      <td>${handlePrice(item)}</td>
                      <td>{item.status}</td>
                      {weeksRegistered.includes(item.id) ? (
                        <td>
                          <Row>
                            <Col>Registered</Col>
                            <Col>
                              <Button variant="danger" onClick={() => handleUnregister(item)}>
                                Unregister
                              </Button>
                            </Col>
                          </Row>
                        </td>
                      ) : (
                        <td>
                          <select onChange={handleChange(item.id)} defaultValue={"not-reg"}>
                            <option value="not-reg">Not Registered</option>
                            <option value="reg">Register</option>
                          </select>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td> No Camp Weeks This Year </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <p>
              * Unregistering from a week will generate a credit for what you paid. That credit may be used toward a
              week that has space. There are no refunds for cancelations or for missed days.
            </p>
            <br />
            <em>Every camper gets 1 free shirt.</em>
            <p>
              {camper?.firstName} currently has <u>{camper?.numShirts}</u> shirt(s).
            </p>
            <br /># of Additional T-Shirts (${shirtPrice} each) &nbsp;
            <input
              type="number"
              min="0"
              placeholder="0"
              className="numberInput"
              onChange={(e) => setNumShirts(parseInt(e.target.value ? e.target.value : "0"))}
              defaultValue="0"
            />
            <br />
            <br />
            <div className="center">
              <p>
                <input
                  type="checkbox"
                  style={{ width: "auto", marginRight: 10 }}
                  onClick={() => setCheckoutDisabled(!checkoutDisabled)}
                />
                <b>***</b> I confirm that {camper?.firstName} {camper?.lastName} will be in{" "}
                <u>Grade {gradeToString(camper!.grade)} in the UPCOMING FALL</u>
                <b>***</b>
              </p>
              <p>
                You can update {camper?.firstName} {camper?.lastName}'s grade <a href={"#/parent/camperForm"}>here</a>
              </p>
              <br />
              <Button
                variant="success"
                className="buttonTxt"
                type="submit"
                onClick={handleSubmit}
                disabled={!isAdmin && isRegistrationClosed}
              >
                Checkout
              </Button>
            </div>
          </Form>
        </Container>
      )}
    </div>
  );
}
