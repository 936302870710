// Component used to show different types of alert messages

import { Alert, Spinner } from "react-bootstrap";
import React from "react";
import { AlertProps } from "react-bootstrap";

const ParentAlert: React.FC = () => {
  return (
    <Alert
      style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      variant="info"
    >
      <Spinner animation="grow"></Spinner>
      <p style={{ textAlign: "center" }}>
        Please double check 'Camper(s)' information and ensure grade, school,
        and shirt size are current.
        <br />
        Also, if your address or contact information has changed, please make
        those changes under 'Guardian Information'
      </p>
    </Alert>
  );
};

const DismissableAlert: React.FC<AlertProps> = (props) => {
  const [show, setShow] = React.useState(true);
  return (
    <Alert
      {...props}
      variant="success"
      dismissible
      onClose={() => setShow(false)}
      show={show}
    >
      {props.children}
    </Alert>
  );
};

export { ParentAlert, DismissableAlert };
