import "./Dashboard.css";
import React from "react";
import {
  Container,
  Button,
  Col,
  Row,
  Form,
  ToggleButtonGroup,
  ToggleButton,
  Modal,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import axios from "axios";
import {
  Camp_Week,
  Group,
  Counselor,
  Registered_Camper_WeekWithCamper,
  Registered_Counselor_WeekWithCounselor,
  Registered_Camper_WeeksWithCamp_WeeksAndGroups,
  User,
} from "./models/models";
import { filterAndSortWeeksCurrentYear, sortGroups } from "./utils/FilterAndSortUtil";
import { findGradeLevels, findCamperBuddyGroupIndex } from "./utils/GroupUtil";
import GroupTable from "./components/GroupTable";
import { useHistory } from "react-router-dom";
import { getAuth } from "firebase/auth";

export default function Groups() {
  const auth = getAuth();
  const history = useHistory();
  const [user, setUser] = React.useState<User>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [refresh, setRefresh] = React.useState(false);
  const [weeks, setWeeks] = React.useState<Camp_Week[]>([]);
  const [groups, setGroups] = React.useState<Group[]>([]);
  // Registered camper weeks
  const [registeredCamperWeeksWithCampers, setRegisteredCamperWeeksWithCampers] = React.useState<
    Registered_Camper_WeekWithCamper[]
  >([]);
  const [registeredCounselorWeeksWithCounselors, setRegisteredCounselorWeeksWithCounselors] = React.useState<
    Registered_Counselor_WeekWithCounselor[]
  >([]);
  const [selectedWeek, setSelectedWeek] = React.useState<number>(0);
  const [selectedGroupType, setSelectedGroupType] = React.useState("All");
  const [selectedGroup, setSelectedGroup] = React.useState<Group>();
  const [showCounselorPopup, setShowCounselorPopup] = React.useState(false);
  const [showCamperPopup, setShowCamperPopup] = React.useState(false);
  const [showRearrangeGroupsPopup, setShowRearrangeGroupsPopup] = React.useState(false);
  const [counselors, setCounselors] = React.useState<Counselor[]>([]);
  const [camperBuddyGroups, setCamperBuddyGroups] = React.useState<[number[]]>([[]]);
  const [groupForEachWeek, setGroupForEachWeek] = React.useState<{ [id: number]: string[] }>({});

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        let weeks: Camp_Week[] = [];
        setIsLoading(true);
        await axios.get(process.env.REACT_APP_API + "api/users/getUser/" + user.uid).then((res) => {
          setUser(res.data);
        });
        await axios.get(process.env.REACT_APP_API + "api/camp_weeks/getCamp_Weeks").then((res) => {
          weeks = filterAndSortWeeksCurrentYear(res.data);
          setWeeks(weeks);
          setSelectedWeek(weeks[0].id);
        });
        await axios.get(process.env.REACT_APP_API + "api/groups/getGroups").then((res) => {
          setGroups(sortGroups(res.data));
        });
        await fetchRegisteredCounselorWeeksWithCounselors();
        await fetchRegisteredCamperWeeksWithCampers();
        await axios.get(process.env.REACT_APP_API + "api/counselors/getCounselors").then((res) => {
          setCounselors(res.data);
        });
        await axios.get(process.env.REACT_APP_API + "api/camper_buddies/getAllCamperBuddyGroups").then((res) => {
          setCamperBuddyGroups(res.data);
        });
        await createGroupForEachWeek(weeks);
      }
      setIsLoading(false);
    });

    return unsubscribe;
  }, [auth]);

  const fetchRegisteredCounselorWeeksWithCounselors = async () => {
    await axios
      .get(process.env.REACT_APP_API + "api/registered_counselor_weeks/getRegistered_Counselor_WeeksWithCounselors")
      .then((res) => {
        setRegisteredCounselorWeeksWithCounselors(res.data);
      });
  };

  const fetchRegisteredCamperWeeksWithCampers = async () => {
    await axios
      .get(process.env.REACT_APP_API + "api/registered_camper_weeks/getRegistered_Camper_WeeksWithCampers")
      .then((res) => {
        setRegisteredCamperWeeksWithCampers(res.data);
      });
  };

  const createGroupForEachWeek = async (weeks: Camp_Week[]) => {
    await axios
      .get(process.env.REACT_APP_API + "api/registered_camper_weeks/getRegistered_Camper_WeeksWithCamp_WeeksAndGroups")
      .then((res) => {
        let registeredCamperWeeksWithCampWeeksAndGroup: Registered_Camper_WeeksWithCamp_WeeksAndGroups[] = res.data;
        let camper_ids = registeredCamperWeeksWithCampWeeksAndGroup.map((item) => item.camper_id);
        for (let camper_id of camper_ids) {
          let singleCamperWeeksWithCampWeeksAndGroup = registeredCamperWeeksWithCampWeeksAndGroup
            .filter((item) => item.camper_id === camper_id)
            .sort((a, b) => a.week_name.localeCompare(b.week_name));
          groupForEachWeek[camper_id] = [];
          for (let i = 0; i < weeks.length; i++) {
            groupForEachWeek[camper_id].push("0");
          }
          for (let campWeekAndGroup of singleCamperWeeksWithCampWeeksAndGroup) {
            // todo: update groupForEachWeek when campers are assigned or removed to groups
            if (campWeekAndGroup.group_name === "Waitlist") {
              groupForEachWeek[camper_id][weeks.findIndex((week) => week.name === campWeekAndGroup.week_name)] = "W";
            } else {
              groupForEachWeek[camper_id][weeks.findIndex((week) => week.name === campWeekAndGroup.week_name)] =
                campWeekAndGroup.group_name.slice(-1);
            }
          }
        }
        setGroupForEachWeek(groupForEachWeek);
      });
  };

  const handleGroupChange = (value: string) => {
    setSelectedGroupType(value);
  };

  const handleWeekChange = (e: { target: { value: string } }) => {
    setSelectedWeek(parseInt(e.target.value));
  };

  // Counselor Popup Functions

  const onClickShowCounselorPopup = (group: Group) => {
    setSelectedGroup(group);
    setShowCounselorPopup(true);
  };

  const removeCounselorFromGroup = async (id: number) => {
    await axios.delete(
      process.env.REACT_APP_API + "api/registered_counselor_weeks/deleteRegistered_Counselor_Week/" + id
    );
    await fetchRegisteredCounselorWeeksWithCounselors();
  };

  const assignCounselor = async (id: string) => {
    setShowCounselorPopup(false);
    await axios.post(process.env.REACT_APP_API + "api/registered_counselor_weeks/addRegistered_Counselor_Week", {
      counselor_id: id,
      camp_week_id: selectedWeek,
      group_id: selectedGroup?.id,
    });
    await fetchRegisteredCounselorWeeksWithCounselors();
  };

  const filteredCounselors = () => {
    // loop through counselors, filter counselors to exclude those that are assigned to any group for the selected week
    const counselorsRegisteredForSelectedWeek = registeredCounselorWeeksWithCounselors
      .filter((item) => item.camp_week_id === selectedWeek)
      .map((item) => item.counselor_id);
    return counselors.filter(
      (counselor) => counselor.active && !counselorsRegisteredForSelectedWeek.includes(counselor.id)
    );
  };

  // Camper Popup Functions

  const onClickShowCamperPopup = (group: Group) => {
    setSelectedGroup(group);
    setShowCamperPopup(true);
  };

  const removeCamperFromGroup = async (item: Registered_Camper_WeekWithCamper) => {
    await axios.put(process.env.REACT_APP_API + "api/registered_camper_weeks/updateRegistered_Camper_Week/" + item.id, {
      camper_id: item.camper_id,
      camp_week_id: item.camp_week_id,
      group_id: null,
    });
    await fetchRegisteredCamperWeeksWithCampers();
    await createGroupForEachWeek(weeks);
    setRefresh(!refresh);
  };

  const filteredCampers = (withGrade = false) => {
    // loop through registeredCamperWeeksWithCamper, filter campers to exclude those that are assigned to any group for the selected week
    let gradeLevels: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8];
    if (selectedGroup && withGrade) {
      gradeLevels = findGradeLevels(selectedGroup.name);
    }
    return registeredCamperWeeksWithCampers.filter(
      (item) => item.camp_week_id === selectedWeek && item.group_id === null && gradeLevels.includes(item.grade)
    );
  };

  const assignCamper = async (item: Registered_Camper_WeekWithCamper) => {
    // Check to see if camp group is full
    const numOfCampersInGroup = registeredCamperWeeksWithCampers.filter(
      (camper) => camper.group_id === selectedGroup?.id
    ).length;
    if (selectedGroup && numOfCampersInGroup >= selectedGroup?.camperLimit) {
      alert("This group is full. Please remove a camper to add another camper.");
      return;
    }
    setShowCamperPopup(false);
    // Add camper to group
    await axios.put(process.env.REACT_APP_API + "api/registered_camper_weeks/updateRegistered_Camper_Week/" + item.id, {
      camper_id: item.camper_id,
      camp_week_id: item.camp_week_id,
      group_id: selectedGroup?.id,
    });
    await fetchRegisteredCamperWeeksWithCampers();
    await createGroupForEachWeek(weeks);
    setRefresh(!refresh);
  };

  const rearrangeGroups = async (weekID: number, groupType: string) => {
    await axios.put(process.env.REACT_APP_API + `api/groups/rearrangeGroups/${weekID}/${groupType}`);
  };

  const handleDownload = () => {
    let csv = "Group Name,Camper Name,Grade,Gender,Buddy ID,Groups\n";
    for (let group of groups) {
      if (group.camp_week_id === selectedWeek) {
        csv += group.name;
        for (let camper of registeredCamperWeeksWithCampers) {
          if (camper.group_id === group.id) {
            csv += `,${camper.firstName} ${camper.lastName},${camper.grade === 0 ? "K" : camper.grade},${
              camper.gender
            },${findCamperBuddyGroupIndex(camperBuddyGroups, camper.camper_id) + 1},${groupForEachWeek[
              camper.camper_id
            ].join("")}\n`;
          }
        }
        csv += "\n";
      }
    }

    const csvData = new Blob([csv], { type: "text/csv" });
    const csvURL = URL.createObjectURL(csvData);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", `${weeks.find((week) => week.id === selectedWeek)?.name} Groups.csv`);
    tempLink.click();
  };

  const handleBack = () => {
    history.goBack();
  };

  const CounselorPopup = () => (
    <Modal scrollable show={showCounselorPopup} onHide={() => setShowCounselorPopup(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Unassigned Counselors</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "300px" }}>
        <ListGroup>
          {filteredCounselors().map((counselor) => (
            <ListGroup.Item key={counselor.id}>
              <Button variant="success" size={"sm"} onClick={() => assignCounselor(counselor.id)}>
                Assign
              </Button>{" "}
              {counselor.firstName} {counselor.lastName}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );

  const CamperPopup = () => (
    <Modal scrollable show={showCamperPopup} onHide={() => setShowCamperPopup(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Unassigned Campers</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "300px" }}>
        <ListGroup>
          {filteredCampers(true).map((item) => (
            <ListGroup.Item key={item.id}>
              <Button variant="success" size={"sm"} onClick={() => assignCamper(item)}>
                Assign
              </Button>{" "}
              {item.firstName} {item.lastName} {`(Grade ${item.grade !== 0 ? item.grade : "K"}, ${item.gender})`}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );

  const RearrangegGroupsPopup = () => {
    const groupTypes = ["Dates", "Coconuts", "Trees", "Young Leaders"];
    const week = weeks.find((week) => week.id === selectedWeek)?.name;
    const [selectedGroupTypes, setSelectedGroupTypes] = React.useState<string[]>([]);
    const [isRearranging, setIsRearranging] = React.useState(false);

    // Handle change in checkbox
    const handleGroupSelectionChange = (groupType: string) => {
      if (selectedGroupTypes.includes(groupType)) {
        setSelectedGroupTypes(selectedGroupTypes.filter((type) => type !== groupType));
      } else {
        setSelectedGroupTypes([...selectedGroupTypes, groupType]);
      }
    };

    return (
      <Modal show={showRearrangeGroupsPopup} onHide={() => setShowRearrangeGroupsPopup(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Rearrange Groups ({week})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This will balance groups and group camper buddies together. Select which groups to rearrange for {week}.
          </p>
          <br />
          {groupTypes.map((groupType) => (
            <Form.Check
              key={groupType}
              type="checkbox"
              label={groupType}
              onChange={() => handleGroupSelectionChange(groupType)}
              checked={selectedGroupTypes.includes(groupType)}
            />
          ))}
          <br />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{ marginRight: 10 }}
              variant="success"
              disabled={isRearranging}
              onClick={async () => {
                setIsRearranging(true);
                for (let groupType of selectedGroupTypes) {
                  await rearrangeGroups(selectedWeek, groupType);
                }
                setIsRearranging(false);
                setShowRearrangeGroupsPopup(false);
                await fetchRegisteredCamperWeeksWithCampers();
                await createGroupForEachWeek(weeks);
                setRefresh(!refresh);
              }}
            >
              {isRearranging ? "Rearranging Groups..." : "Rearrange Groups"}
            </Button>
            <Spinner animation="border" variant="primary" hidden={!isRearranging} />
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <Container className="Admin-Buttons">
      <Button variant="primary" className="backButton" onClick={handleBack}>
        Back
      </Button>
      <br />
      <br />
      <h3>Groups</h3>
      {isLoading ? (
        <div className="center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className="center">
          <p>Click on the group types to assign counselors and campers.</p>
          <br />
          <Form className="center" style={{ marginLeft: "40%", marginRight: "40%" }}>
            <Form.Group as={Col}>
              <Form.Control as="select" onChange={handleWeekChange} style={{ textAlign: "center" }}>
                {weeks.map((week) => (
                  <option key={week.id} value={week.id}>
                    {week.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
          {user && user.role === "admin" && (
            <ToggleButtonGroup type="radio" name="options" onChange={handleGroupChange}>
              <ToggleButton value={"All"} variant="outline-dark">
                All
              </ToggleButton>
              <ToggleButton value={"Dates"} variant="outline-success">
                Dates
              </ToggleButton>
              <ToggleButton value={"Coconuts"} variant="outline-warning">
                Coconuts
              </ToggleButton>
              <ToggleButton value={"Trees"} variant="outline-danger">
                Trees
              </ToggleButton>
              <ToggleButton value={"Young Leaders"} variant="outline-info">
                Young Leaders
              </ToggleButton>
              <ToggleButton value={"Waitlist"} variant="outline-secondary">
                Waitlist
              </ToggleButton>
            </ToggleButtonGroup>
          )}

          <Container>
            <br />
            <br />
            {selectedGroupType === "All" ? <h4>👥 All Groups 👥</h4> : <h4>✏️ Edit Group: {selectedGroupType}</h4>}
            <Container style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 25 }}>
              <Button style={{ marginBottom: 15 }} onClick={handleDownload}>
                Download Groups for {weeks.find((week) => week.id === selectedWeek)?.name} (CSV File)
              </Button>
              <Button onClick={() => setShowRearrangeGroupsPopup(true)}>
                Rearrange Groups for {weeks.find((week) => week.id === selectedWeek)?.name}
              </Button>
            </Container>
            <br />
            <p>The "Groups" label shows which group a camper is in for each week. </p>
            <p>
              Example: "102W" {"->"} (Week 1: Dates 1, Week 2: Not Registered or Unassigned, Week 3: Dates 2, Week 4:
              Waitlist)
            </p>
            <div className={selectedGroupType === "All" ? "grid-container" : ""}>
              {groups
                .filter((group) => group.camp_week_id === selectedWeek)
                .filter((group) => (selectedGroupType === "All" ? true : group.name.includes(selectedGroupType)))
                .map((group) => (
                  <div key={group.id}>
                    <Row>
                      <Col>
                        <GroupTable
                          group={group}
                          counselors={registeredCounselorWeeksWithCounselors.filter(
                            (item) => item.group_id === group.id && item.camp_week_id === selectedWeek
                          )}
                          campers={registeredCamperWeeksWithCampers.filter(
                            (item) => item.group_id === group.id && item.camp_week_id === selectedWeek
                          )}
                          groupForEachWeek={groupForEachWeek}
                          camperBuddyGroups={camperBuddyGroups}
                          mutable={selectedGroupType !== "All"}
                          onRemoveCounselorClick={removeCounselorFromGroup}
                          onRemoveCamperClick={removeCamperFromGroup}
                        />
                      </Col>
                      {selectedGroupType !== "All" && (
                        <Col xs="auto">
                          <br />
                          <br />
                          <br />
                          <Button variant="outline-primary" onClick={() => onClickShowCounselorPopup(group)}>
                            + Assign Counselor
                          </Button>
                          <br />
                          <br />
                          <Button variant="primary" onClick={() => onClickShowCamperPopup(group)}>
                            + Assign Camper
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </div>
                ))}
            </div>
            <div>
              <br />
              <br />
              <h5>Unassigned Campers</h5>
              <ListGroup>
                {filteredCampers().map((item) => (
                  <ListGroup.Item key={item.id}>
                    {item.firstName} {item.lastName} {`(Grade ${item.grade !== 0 ? item.grade : "K"}, ${item.gender})`}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          </Container>

          <CounselorPopup />
          <CamperPopup />
          <RearrangegGroupsPopup />
        </div>
      )}
    </Container>
  );
}
