// Utility functions for handling date and time
// dateTime refers to the datetime string that gets returned from SQl queries
// e.g. "Mon, 27 Jun 2022 09:00:00 GMT"

export function dateTimeToTime(dateTime: string) {
  // Example: Mon, 27 Jun 2022 09:00:00 GMT --> 9:00AM
  const militaryTime = dateTime.substring(17, 22);
  const hour = parseInt(militaryTime.substring(0, 2));
  if (hour < 12) {
    return `${hour}:${militaryTime.substring(3, 5)} AM`;
  } else {
    return `${hour - 12}:${militaryTime.substring(3, 5)} PM`;
  }
}

export function dateTimeToMilitaryTime(dateTime: string) {
  // Example: Mon, 27 Jun 2022 09:00:00 GMT --> 09:00:00
  return new Date(dateTime.substring(0, 25)).toTimeString().substring(0, 8);
}

export function dateTimeToDateInput(dateTime: string) {
  // Example: Mon, 27 Jun 2022 09:00:00 GMT --> 2022-06-27
  return new Date(dateTime).toISOString().substring(0, 10);
}

export function dateTimeToDate(dateTime: string) {
  // Example: Mon, 27 Jun 2022 09:00:00 GMT --> Mon, 27 Jun 2022
  return dateTime.substring(0, 16);
}

export function dateTimeToDateTimeInput(dateTime: string) {
  // Example: Mon, 27 Jun 2022 09:00:00 GMT --> 2022-06-27T09:00
  return new Date(dateTime).toISOString().substring(0, 16);
}

export function getCurrentLocalDateTime() {
  const date = new Date();
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are 0 based index
  const day = ("0" + date.getDate()).slice(-2);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
