import React from "react";
import { Container, Button, Form, Row, Col, Spinner, Modal } from "react-bootstrap";
import Campers from "./components/Campers";
import { Parent } from "./models/models";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { LoadingSpinner } from "./components/LoadingIcons";

interface CamperTruncated {
  id: number;
  firstName: string;
  lastName: string;
}

export default function ManageCampers() {
  const history = useHistory();
  const [campers, setCampers] = React.useState<CamperTruncated[]>([]);
  const [parentsNotSignedEF, setParentsNotSignedEF] = React.useState<Parent[]>([]);
  const [showEFReminderPopup, setShowEFReminderPopup] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingParentsNotSignedEF, setIsLoadingParentsNotSignedEF] = React.useState(true);
  const queryRef = React.useRef<any>(null);
  const sortRef = React.useRef<any>(null);

  React.useEffect(() => {
    (async () => {
      await handleQueryChange({ target: { value: "registeredCurrentYear" } });
      await handleSortOptionChange({ target: { value: "firstName(A-Z)" } });
      if (sortRef) {
        sortRef.current.selectedIndex = 0;
      }
      // Get parents who have not signed emergency form
      setIsLoadingParentsNotSignedEF(true);
      await axios.get(process.env.REACT_APP_API + "api/parents/getParentsNotSignedEF").then((response) => {
        setParentsNotSignedEF(response.data);
      });
      setIsLoadingParentsNotSignedEF(false);
    })();
  }, []);

  const handleQueryChange = async (e: { target: { value: string } }) => {
    setIsLoading(true);
    const query = e.target.value;
    if (query === "registeredCurrentYear") {
      await axios.get(process.env.REACT_APP_API + "api/campers/getCampersRegisteredCurrentYear").then((response) => {
        setCampers(response.data);
      });
    } else if (query === "registeredAllYears" && sortRef.current.value !== "registered(mostRecent)") {
      await axios.get(process.env.REACT_APP_API + "api/campers/getCampersRegisteredAllYears").then((response) => {
        setCampers(response.data);
      });
    } else if (query === "neverRegistered") {
      await axios.get(process.env.REACT_APP_API + "api/campers/getCampersNeverRegistered").then((response) => {
        setCampers(response.data);
      });
    }
    handleSortOptionChange({ target: { value: sortRef.current.value } });
    setIsLoading(false);
  };

  const handleSortOptionChange = async (e: { target: { value: string } }) => {
    setIsLoading(true);
    const sortOption = e.target.value;
    if (sortOption === "firstName(A-Z)") {
      setCampers((c) => [...c.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))]);
    } else if (sortOption === "lastName(A-Z)") {
      setCampers((c) => [...c.sort((a, b) => (a.lastName > b.lastName ? 1 : -1))]);
    } else if (sortOption === "registered(mostRecent)") {
      if (queryRef) {
        queryRef.current.selectedIndex = 1;
      }
      await axios
        .get(process.env.REACT_APP_API + "api/campers/getCampersRegisteredAllYearsSortByRegistrationDate")
        .then((res) => {
          setCampers(res.data);
        });
    }
    setIsLoading(false);
  };

  const onGenerateEmergencyForm = async () => {
    sessionStorage.setItem("camper_id", campers.map((c) => c.id).join(","));
    history.push(`/admin/emergencyForm`);
  };

  const EFReminderPopup = () => (
    <Modal scrollable show={showEFReminderPopup} onHide={() => setShowEFReminderPopup(false)}>
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center" }}>Parents Who Have Not Signed the Emergency Form</Modal.Title>
      </Modal.Header>
      {isLoadingParentsNotSignedEF ? (
        <LoadingSpinner animation="border" style={{ margin: 10 }} />
      ) : (
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}>
            <Button variant="success" disabled={true} onClick={async () => {}}>
              Send Reminder Email (in development)
            </Button>
          </div>
          <div>
            {parentsNotSignedEF.map((parent) => (
              <p key={parent.id}>
                {parent.firstName} {parent.lastName} ({parent.email})
              </p>
            ))}
          </div>
        </Modal.Body>
      )}
    </Modal>
  );

  return (
    <div className="ManageCampers">
      <br />
      <Container className="Admin-Buttons">
        <Button variant="primary" className="backButton" href="/#/admin">
          Back
        </Button>
        <br />
        <br />
        <h3> Manage Campers </h3>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            float: "right",
            marginTop: 25,
            gap: 30,
          }}
        >
          <Button variant="outline-primary" onClick={onGenerateEmergencyForm}>
            Generate Emergency Forms
          </Button>
          <Button onClick={() => setShowEFReminderPopup(true)}>View Parents Not Signed EF</Button>
        </div>
        <br />
        <Form style={{ marginLeft: "30%", marginRight: "20%" }}>
          <Row>
            <Form.Label>Filter: </Form.Label>
            <Form.Group as={Col} xs="auto" controlId="queryOption">
              <Form.Control ref={queryRef} as="select" style={{ textAlign: "center" }} onChange={handleQueryChange}>
                <option value="registeredCurrentYear">Registered (Current Year)</option>
                <option value="registeredAllYears">Registered (All Years)</option>
                <option value="neverRegistered">Never Registered</option>
              </Form.Control>
            </Form.Group>
          </Row>
          <Row>
            <Form.Label>Sort by: </Form.Label>
            <Form.Group as={Col} xs="auto" controlId="sortOption">
              <Form.Control ref={sortRef} as="select" style={{ textAlign: "center" }} onChange={handleSortOptionChange}>
                <option value="firstName(A-Z)">First Name (A-Z)</option>
                <option value="lastName(A-Z)">Last Name (A-Z)</option>
                <option value="registered(mostRecent)">Registered (Most Recent)</option>
              </Form.Control>
            </Form.Group>
          </Row>
        </Form>
        <br />
        <Row style={{ display: "flex", justifyContent: "space-evenly", marginLeft: "10%", marginRight: "10%" }}>
          <p>👤 Info</p>
          <p>📖 Registered Weeks</p>
          <p>📆 Attendance</p>
          <p>📝 Emergency Form</p>
        </Row>
        <br />
        <div className="col text-center">
          {isLoading ? <Spinner animation="border" variant="primary" /> : <Campers campers={campers} type="admin" />}
        </div>
      </Container>

      <EFReminderPopup />
    </div>
  );
}
