// Page for parents to fill out their child's camper form

import "./HouseholdForm.css";
import React from "react";
import { Button, Container, Form, Row, Col, Tabs, Tab, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { getAuth } from "firebase/auth";
import axios from "axios";
import { dateTimeToDateInput } from "./utils/DateTimeUtil";
import { gradeToString } from "./utils/DataToStringUtil";
import { findGradesInSameGroupType } from "./utils/GroupUtil";
import { LoadingSpinner } from "./components/LoadingIcons";
import { Camper, Camper_Medical_Record, Registered_Camper_WeekWithCamper, Parent, Camper_Buddy } from "./models/models";

export default function CamperForm() {
  const auth = getAuth();
  const history = useHistory();
  const [showDelForm, setDelForm] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isSearching, setIsSearching] = React.useState(false);
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const [isFieldReadOnly, setIsFieldReadOnly] = React.useState(true);
  const [userRole, setUserRole] = React.useState("");
  const [registeredWeeks, setRegisteredWeeks] = React.useState<Registered_Camper_WeekWithCamper[]>([]);
  const [credit, setCredit] = React.useState(0);
  const [parent, setParent] = React.useState<Parent>();
  const [camperBuddies, setCamperBuddies] = React.useState<Camper[]>([]);
  const [camperSearchInput, setCamperSearchInput] = React.useState<string>("");
  const [camperSearchResults, setCamperSearchResults] = React.useState<Camper[]>([]);
  const camper_id = sessionStorage.getItem("camper_id");
  const formMode: "add" | "edit" = sessionStorage.getItem("camper_id") === "" ? "add" : "edit";

  const [camperValues, setCamperValues] = React.useState<Camper>({
    id: 0,
    parent_id: "",
    firstName: "",
    lastName: "",
    gender: "male",
    dob: "",
    grade: 0,
    school: "",
    shirtSize: "M",
    numShirts: 1,
    paid: 0,
    created_at: "",
  });

  const [medicalRecordValues, setMedicalRecordValues] = React.useState<Camper_Medical_Record>({
    id: 0,
    camper_id: 0,
    doctorName: "",
    doctorPhone: "",
    insuranceCarrier: "",
    policyHolder: "",
    allergies: "",
    restrictedActivities: "",
    illnesses: "",
    immunizations: "",
    medicalTreatments: "",
    medications: "",
    tetanusDate: "",
    comments: "",
  });

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setIsLoading(true);
      if (user) {
        await fetchCamperData();
        await fetchCamperBuddies();
        await axios.get(process.env.REACT_APP_API + "api/users/getUser/" + user.uid).then((res) => {
          if (res.data.role === "admin") {
            setIsFieldReadOnly(false);
          }
          setUserRole(res.data.role);
        });
      }
      setIsLoading(false);
    });
    return unsubscribe;
  }, [auth]);

  const fetchCamperData = async () => {
    const camper_id = sessionStorage.getItem("camper_id");
    if (camper_id) {
      await axios
        .get(process.env.REACT_APP_API + "api/campers/getCamper/" + camper_id)
        .then(async (res) => {
          setCamperValues({
            ...res.data,
            dob: dateTimeToDateInput(res.data.dob),
          });
          await axios.get(process.env.REACT_APP_API + "api/parents/getParent/" + res.data.parent_id).then((res) => {
            setParent(res.data);
            setCredit(res.data.credit);
          });
        })
        .then(async () => {
          await axios
            .get(
              process.env.REACT_APP_API + "api/camper_medical_records/getCamper_Medical_RecordByCamperID/" + camper_id
            )
            .then((res) => {
              setMedicalRecordValues({
                ...res.data,
                tetanusDate: dateTimeToDateInput(res.data.tetanusDate),
              });
            });
        });
      await axios
        .get(
          process.env.REACT_APP_API +
            "api/registered_camper_weeks/getRegistered_Camper_WeeksWithCamp_WeeksByCamperID/" +
            camper_id
        )
        .then((res) => {
          setRegisteredWeeks(
            res.data.filter((week: any) => week.registered_camp_week_id !== null && new Date() < new Date(week.start))
          );
        });
    } else {
      setIsFieldReadOnly(false);
    }
  };

  const fetchCamperBuddies = async () => {
    if (camper_id) {
      await axios
        .get(process.env.REACT_APP_API + "api/camper_buddies/getCamperBuddies/" + camper_id)
        .then(async (res) => {
          const buddiesIDs: Camper[] = res.data;
          const camperBuddies: Camper[] = [];
          for (let buddyID of buddiesIDs) {
            await axios.get(process.env.REACT_APP_API + "api/campers/getCamper/" + buddyID.id).then((res) => {
              camperBuddies.push(res.data);
            });
          }
          setCamperBuddies(camperBuddies);
        });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);
    let newCamperID = 0;
    if (formMode === "add") {
      await axios
        .post(process.env.REACT_APP_API + "api/campers/addCamper", {
          ...camperValues,
          parent_id: auth.currentUser?.uid,
        })
        .then(async (res) => {
          await axios.post(process.env.REACT_APP_API + "api/camper_medical_records/addCamper_Medical_Record", {
            ...medicalRecordValues,
            camper_id: res.data.camper_id,
          });
          newCamperID = res.data.camper_id;
        });
    } else {
      await axios.put(process.env.REACT_APP_API + "api/campers/updateCamper/" + camper_id, {
        ...camperValues,
      });
      await axios.put(
        process.env.REACT_APP_API + "api/camper_medical_records/updateCamper_Medical_Record/" + camper_id,
        {
          ...medicalRecordValues,
        }
      );
    }
    if (parent) {
      await axios.put(process.env.REACT_APP_API + "api/parents/updateParent/" + parent.id, {
        ...parent,
        credit: credit,
      });
    }
    setIsSaving(false);
    // sessionStorage.removeItem("camper_id");
    if (userRole === "admin") {
      history.push("/admin/manageCampers");
    } else if (userRole === "parent") {
      // set sessionitem of camper_id to camper's id
      if (formMode === "add") {
        sessionStorage.setItem("camper_id", newCamperID.toString());
      }
      history.push("/parent/camperScheduling");
    }
  };

  const handleCamperChange = (name: string) => (e: { target: { value: any } }) => {
    setCamperValues({ ...camperValues, [name]: e.target.value });
  };

  const handleMedicalRecordChange = (name: string) => (e: { target: { value: any } }) => {
    setMedicalRecordValues({
      ...medicalRecordValues,
      [name]: e.target.value,
    });
  };

  const handleDeleteCamper = async (e: { preventDefault: () => void }) => {
    await axios.delete(process.env.REACT_APP_API + "api/campers/deleteCamper/" + sessionStorage.getItem("camper_id"));
    sessionStorage.removeItem("camper_id");
    if (userRole === "admin") {
      history.replace("/admin/manageCampers");
    } else {
      history.replace("/parent/campers");
    }
  };

  const handleDeleteCamperForm = () => {
    // Check to see if the camper has any registered weeks, if so, don't allow them to delete
    if (registeredWeeks.length !== 0) {
      alert("You can't delete a camper that is currently registered for a week. Please unregister them first.");
    } else {
      setDelForm(true);
    }
  };

  const handleCancelDeleteCamperForm = () => {
    setDelForm(false);
  };

  const handleSearchCamper = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSearching(true);
    await axios.get(process.env.REACT_APP_API + "api/campers/getCamperByName/" + camperSearchInput).then((res) => {
      setCamperSearchResults(filterSearchResults(res.data));
    });
    setIsSearching(false);
  };

  const filterSearchResults = (results: Camper[]) => {
    // Filter out campers that are in the same grade group as the current camper and are the current camper
    results = results.filter((camper: Camper) => {
      return camper.id !== Number(camper_id) && findGradesInSameGroupType(camper.grade).includes(camperValues.grade);
    });
    // Filter out campers that are already buddies with the current camper
    results = results.filter((camper) => {
      for (let buddy of camperBuddies) {
        if (buddy.id === camper.id) {
          return false;
        }
      }
      return true;
    });
    return results;
  };

  const handleAddCamperBuddy = async (buddyCamperID: number) => {
    setIsRefreshing(true);
    await axios.post(process.env.REACT_APP_API + "api/camper_buddies/addCamperBuddy", {
      camper1_id: Number(camper_id),
      camper2_id: buddyCamperID,
    });
    await fetchCamperBuddies();
    // Remove added buddy from search results
    setCamperSearchResults(camperSearchResults.filter((camper) => camper.id !== buddyCamperID));
    setIsRefreshing(false);
  };

  const handleDeleteCamperBuddy = async (buddyCamperID: number) => {
    setIsRefreshing(true);
    await axios.delete(
      process.env.REACT_APP_API + "api/camper_buddies/deleteCamperBuddy/" + camper_id + "/" + buddyCamperID
    );
    await fetchCamperBuddies();
    setIsRefreshing(false);
  };

  const handleGoBack = () => {
    // sessionStorage.removeItem("camper_id");
    history.goBack();
  };

  const DelForm = () => (
    <div className="form-popup" id="myForm">
      <p> Are you sure you want to delete this camper? </p>
      <Button variant="danger" className="formBtn" onClick={handleDeleteCamper}>
        Delete
      </Button>
      <br />
      <Button variant="secondary" className="formBtn" onClick={handleCancelDeleteCamperForm}>
        Cancel
      </Button>
    </div>
  );

  return isLoading ? (
    <div className="CamperForm">
      <Container className="Text-Form">
        <LoadingSpinner style={{ margin: "auto" }} animation={"border"} />
      </Container>
    </div>
  ) : (
    <div className="CamperForm">
      <br />
      <Container className="Text-Form">
        <Button variant="primary" className="backButton" onClick={handleGoBack}>
          Back
        </Button>
        <br />
        <h3>Camper Form ({`${camperValues.firstName} ${camperValues.lastName}`})</h3>
        <br />
        <Tabs justify>
          <Tab eventKey="camper_form" title="Info">
            <br />
            <p>
              <b>*</b> Indicates a mandatory field.
            </p>
            {camper_id ? (
              <p>
                Note: Please email info@campizza.com to change camper's <u>name</u>.
              </p>
            ) : null}

            <Form onSubmit={handleSubmit}>
              <div className="center" style={{ margin: 20 }}>
                {formMode === "add" ? (
                  <Button
                    type="submit"
                    variant="success"
                    className="buttonTxt"
                    disabled={isSaving}
                    style={{ width: "auto" }}
                  >
                    {isSaving ? "Adding..." : "Add Camper"}
                  </Button>
                ) : (
                  <Button type="submit" variant="success" className="buttonTxt" disabled={isSaving}>
                    {isSaving ? "Saving..." : "Save"}
                  </Button>
                )}
              </div>
              <h5>Camper Info</h5>
              <Row>
                <Form.Group as={Col} className="form-field-md" controlId="camperFirstName">
                  <Form.Label>
                    <b>* </b>First Name
                  </Form.Label>
                  <Form.Control
                    required
                    readOnly={isFieldReadOnly}
                    value={camperValues.firstName}
                    onChange={handleCamperChange("firstName")}
                  />
                </Form.Group>
                <Form.Group as={Col} className="form-field-md" controlId="camperLirstName">
                  <Form.Label>
                    <b>* </b>Last Name
                  </Form.Label>
                  <Form.Control
                    required
                    readOnly={isFieldReadOnly}
                    value={camperValues.lastName}
                    onChange={handleCamperChange("lastName")}
                  />
                </Form.Group>
                <Form.Group as={Col} xs="3" className="form-field-sm" controlId="camperGender">
                  <Form.Label>
                    <b>* </b>Gender
                  </Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    required
                    value={camperValues.gender}
                    onChange={handleCamperChange("gender")}
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Form.Control>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} className="form-field-md" controlId="camperDob">
                  <Form.Label>
                    <b>* </b>Date of Birth
                  </Form.Label>
                  <Form.Control type="date" value={camperValues.dob} required onChange={handleCamperChange("dob")} />
                </Form.Group>
                <Form.Group as={Col} className="form-field-sm" controlId="camperShirtSize">
                  <Form.Label>
                    <b>* </b>Shirt Size
                  </Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    required
                    value={camperValues.shirtSize}
                    onChange={handleCamperChange("shirtSize")}
                  >
                    <option value="XS">XS</option>
                    <option value="S">S</option>
                    <option value="M">M</option>
                    <option value="L">L</option>
                    <option value="XL">XL</option>
                  </Form.Control>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} className="form-field-md" controlId="camperGrade">
                  <Form.Label>
                    <b>* </b>Grade Level in <u>UPCOMING FALL</u>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    required
                    value={camperValues.grade}
                    onChange={handleCamperChange("grade")}
                  >
                    <option value={0}>K</option>
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((grade) => (
                      <option key={grade} value={grade}>
                        {grade}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} className="form-field-md" controlId="camperSchool">
                  <Form.Label>
                    <b>* </b>School Name
                  </Form.Label>
                  <Form.Control required value={camperValues.school} onChange={handleCamperChange("school")} />
                </Form.Group>
              </Row>

              <br />
              <h5>Health Info</h5>
              <Row>
                <Form.Group as={Col} className="form-field-md" controlId="doctorName">
                  <Form.Label>
                    <b>* </b>Primary Physician's Name
                  </Form.Label>
                  <Form.Control
                    required
                    value={medicalRecordValues.doctorName}
                    onChange={handleMedicalRecordChange("doctorName")}
                  />
                </Form.Group>
                <Form.Group as={Col} className="form-field-md" controlId="doctorPhone">
                  <Form.Label>
                    <b>* </b>Primary Physician's Phone Number
                  </Form.Label>
                  <Form.Control
                    type="tel"
                    pattern="[0-9]{10,12}"
                    placeholder="6261234567"
                    required
                    value={medicalRecordValues.doctorPhone}
                    onChange={handleMedicalRecordChange("doctorPhone")}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} className="form-field-md" controlId="insuranceCarrier">
                  <Form.Label>
                    <b>* </b>Insurance Carrier
                  </Form.Label>
                  <Form.Control
                    required
                    value={medicalRecordValues.insuranceCarrier}
                    onChange={handleMedicalRecordChange("insuranceCarrier")}
                  />
                </Form.Group>
                <Form.Group as={Col} className="form-field-md" controlId="policyHolder">
                  <Form.Label>
                    <b>* </b>Policy Holder's Name
                  </Form.Label>
                  <Form.Control
                    required
                    value={medicalRecordValues.policyHolder}
                    onChange={handleMedicalRecordChange("policyHolder")}
                  />
                </Form.Group>
              </Row>

              <br />
              <h5>Health Questionnaire</h5>
              <p>
                <b>* </b>Please answer the following questions. <u>Leave blank</u> if quesions do not apply.
              </p>
              <br />
              <Row>
                <Form.Group as={Col} controlId="allergies">
                  <Form.Label>
                    Does your child have any allergies and/or dietary restrictions? If <u>yes</u>, please list
                    restrictions.
                  </Form.Label>
                  <Form.Control
                    value={medicalRecordValues.allergies}
                    onChange={handleMedicalRecordChange("allergies")}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="restrictedActivities">
                  <Form.Label>
                    Are there any camp activities that your child cannot participate in? If <u>yes</u>, please list
                    activities.
                  </Form.Label>
                  <Form.Control
                    value={medicalRecordValues.restrictedActivities}
                    onChange={handleMedicalRecordChange("restrictedActivities")}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="illnesses">
                  <Form.Label>
                    Does your child have any chronic conditions or illnesses? If <u>yes</u>, please list conditions.
                  </Form.Label>
                  <Form.Control
                    value={medicalRecordValues.illnesses}
                    onChange={handleMedicalRecordChange("illnesses")}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="medications">
                  <Form.Label>
                    Will your child be taking any medication at camp? If <u>yes</u>, please list medications.
                  </Form.Label>
                  <Form.Control
                    value={medicalRecordValues.medications}
                    onChange={handleMedicalRecordChange("medications")}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="medicalTreatments">
                  <Form.Label>
                    Has your child undergone any medical treatments? If <u>yes</u>, please list treatments.
                  </Form.Label>
                  <Form.Control
                    value={medicalRecordValues.medicalTreatments}
                    onChange={handleMedicalRecordChange("medicalTreatments")}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="immunizations">
                  <Form.Label>
                    <b>* </b>Has your child received all current immunizations?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    value={medicalRecordValues.immunizations}
                    onChange={handleMedicalRecordChange("immunizations")}
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Control>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="tetanusDate">
                  <Form.Label>
                    <b>* </b>What is the date (approximate if necessary) of your child's last tetanus shot?
                  </Form.Label>
                  <Form.Control
                    type="date"
                    required
                    value={medicalRecordValues.tetanusDate}
                    onChange={handleMedicalRecordChange("tetanusDate")}
                  />
                </Form.Group>
              </Row>

              <br />
              <h5>Extra Comments</h5>
              <Row>
                <Form.Group as={Col} controlId="comments">
                  <Form.Label>Do you have any additional comments about this camper?</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={medicalRecordValues.comments}
                    onChange={handleMedicalRecordChange("comments")}
                  />
                </Form.Group>
              </Row>

              <div className="center">
                {formMode === "add" ? (
                  <Button
                    type="submit"
                    variant="success"
                    className="buttonTxt"
                    disabled={isSaving}
                    style={{ width: "auto" }}
                  >
                    {isSaving ? "Adding..." : "Add Camper"}
                  </Button>
                ) : (
                  <Button type="submit" variant="success" className="buttonTxt" disabled={isSaving}>
                    {isSaving ? "Saving..." : "Save"}
                  </Button>
                )}
              </div>
              <br />
              <hr />
              <br />
              {sessionStorage.getItem("camper_id") && (
                <div className="center">
                  <Button variant="danger" onClick={handleDeleteCamperForm}>
                    Delete Camper
                  </Button>
                </div>
              )}

              {showDelForm && <DelForm />}
            </Form>
          </Tab>
          {camper_id && (
            <Tab eventKey="camper_buddies" title="Buddies">
              <br />
              <p>
                If you'd like {camperValues.firstName} to be in a group with other campers, please select them below.
              </p>
              <br />
              <h5>{camperValues.firstName}'s Camper Buddies</h5>
              {isRefreshing ? (
                <LoadingSpinner animation={"border"} />
              ) : (
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Grade</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {camperBuddies.map((buddy) => (
                      <tr key={buddy.id}>
                        <td>{buddy.firstName}</td>
                        <td>{buddy.lastName}</td>
                        <td>{gradeToString(buddy.grade)}</td>
                        <td>
                          {
                            <Button variant="danger" onClick={() => handleDeleteCamperBuddy(buddy.id)}>
                              Remove
                            </Button>
                          }
                        </td>
                      </tr>
                    ))}
                    {camperBuddies.length === 0 && (
                      <tr>
                        <td colSpan={4}>No camper buddies selected</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
              <br />
              <h5>Search Campers</h5>
              <p>
                If the camper you are searching for is not coming up, it may be because 1) you are not spelling the name
                the way it is spelled in the system, 2) they are not registered yet, or 3) they are not in the same
                grade group as your camper.{" "}
                <u>
                  {camperValues.firstName} is in the group for grades:{" "}
                  {findGradesInSameGroupType(camperValues.grade)
                    .map((grade) => gradeToString(grade))
                    .join(", ")}{" "}
                </u>
              </p>
              <br />
              <Form onSubmit={handleSearchCamper}>
                <Form.Group as={Row} controlId="camperSearch">
                  <Col>
                    <Form.Control
                      type="text"
                      required
                      placeholder="Search by name"
                      value={camperSearchInput}
                      onChange={(e) => setCamperSearchInput(e.target.value)}
                    />
                  </Col>
                  <Col>
                    <Button type="submit" variant="primary" disabled={isSearching}>
                      {isSearching ? "Searching..." : "Search"}
                    </Button>
                  </Col>
                </Form.Group>
              </Form>
              {isSearching ? (
                <LoadingSpinner animation={"border"} />
              ) : (
                <Table bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Grade</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {camperSearchResults.map((buddy) => (
                      <tr key={buddy.id}>
                        <td>{buddy.firstName}</td>
                        <td>{buddy.lastName}</td>
                        <td>{gradeToString(buddy.grade)}</td>
                        <td>
                          {
                            <Button variant="success" onClick={() => handleAddCamperBuddy(buddy.id)}>
                              Add
                            </Button>
                          }
                        </td>
                      </tr>
                    ))}
                    {camperSearchResults.length === 0 && (
                      <tr>
                        <td colSpan={4}>No results found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
            </Tab>
          )}
        </Tabs>
      </Container>
    </div>
  );
}
