import axios from "axios";
import { Group } from "../models/models";

interface GroupWithCamperCount extends Group {
  camperCount: number;
}

// Find what grade levels the group should have
export function findGradeLevels(groupName: string) {
  if (groupName.startsWith("Dates")) {
    return [0, 1];
  } else if (groupName.startsWith("Coconuts")) {
    return [2, 3];
  } else if (groupName.startsWith("Trees")) {
    return [4, 5, 6];
  } else if (groupName.startsWith("Young Leaders")) {
    return [7, 8];
  } else {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8];
  }
}

// Find what group the camper should be in given their grade
export function findGroupType(grade: number) {
  if ([0, 1].includes(grade)) {
    return "Dates";
  } else if ([2, 3].includes(grade)) {
    return "Coconuts";
  } else if ([4, 5, 6].includes(grade)) {
    return "Trees";
  } else if ([7, 8].includes(grade)) {
    return "Young Leaders";
  }
  return "";
}

// Find the list of grades of the group type the camper would be in
export function findGradesInSameGroupType(grade: number) {
  const groupType = findGroupType(grade);
  return findGradeLevels(groupType);
}

// Return true if the groups are full for the given grade and week
export async function areGroupsFull(grade: number, weekID: number) {
  let isGroupFull = true;
  const groupType = findGroupType(grade);

  await axios
    .get(`${process.env.REACT_APP_API}/api/groups/getGroupsByCampWeekIDAndName/${weekID}/${groupType}`)
    .then(async (res) => {
      const groups: GroupWithCamperCount[] = res.data;
      groups.sort((a, b) => a.name.localeCompare(b.name));
      for (let group of groups) {
        const camperCount = (
          await axios.get(`${process.env.REACT_APP_API}/api/groups/getNumOfCampersInGroup/${group.id}`)
        ).data.camperCount;
        group.camperCount = camperCount;
      }

      for (let group of groups) {
        if (group.camperCount < group.camperLimit) {
          isGroupFull = false;
          break;
        }
      }
    });
  return isGroupFull;
}

// Find the group id that the camper should be in based on their grade and week
export async function findGroupID(grade: number, weekID: number) {
  let designatedGroupID: null | number = null;
  const groupType = findGroupType(grade);

  await axios
    .get(`${process.env.REACT_APP_API}/api/groups/getGroupsByCampWeekIDAndName/${weekID}/${groupType}`)
    .then(async (res) => {
      const groups: GroupWithCamperCount[] = res.data;
      groups.sort((a, b) => a.name.localeCompare(b.name));
      for (let group of groups) {
        const camperCount = (
          await axios.get(`${process.env.REACT_APP_API}/api/groups/getNumOfCampersInGroup/${group.id}`)
        ).data.camperCount;
        group.camperCount = camperCount;
      }
      // Assign the camper to the first group that is not full
      for (let group of groups) {
        if (group.camperCount < group.camperLimit) {
          designatedGroupID = group.id;
          break;
        }
      }
      // If all groups are full, put them in the waitlist
      if (designatedGroupID === null) {
        await axios
          .get(`${process.env.REACT_APP_API}/api/groups/getGroupsByCampWeekIDAndName/${weekID}/Waitlist`)
          .then((res) => (designatedGroupID = res.data[0].id));
      }
    });

  // console.log(designatedGroupID);
  return designatedGroupID;
}

// Find the index of the camperBuddyGroup that the camper is in
export function findCamperBuddyGroupIndex(camperBuddyGroups: [number[]], camper_id: number) {
  return camperBuddyGroups.findIndex((group) => group.some((camper) => camper === camper_id));
}
