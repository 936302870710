// Component used in Parent.tsx to display a list of campers of a parent

import "../Dashboard.css";
import { Table, Row, Button, Tab } from "react-bootstrap";
import {
  Group,
  Registered_Counselor_WeekWithCounselor,
  Registered_Camper_WeekWithCamper,
  Registered_Camper_WeeksWithCamp_WeeksAndGroups,
} from "../models/models";
import { findCamperBuddyGroupIndex } from "../utils/GroupUtil";

// NOTE: id values of registered_counselor_week and registered_camper_week are NOT the id values of the counselors and campers, they are thee id values of the registered_counselor_week and registered_camper_week entries in the database
interface props {
  group: Group;
  counselors: Registered_Counselor_WeekWithCounselor[];
  campers: Registered_Camper_WeekWithCamper[];
  camperBuddyGroups: [number[]];
  groupForEachWeek: { [id: number]: string[] };
  mutable?: boolean;
  onRemoveCounselorClick: (id: number) => void;
  onRemoveCamperClick: (item: Registered_Camper_WeekWithCamper) => void;
}

const GroupTable: React.FC<props> = ({
  group,
  counselors,
  campers,
  camperBuddyGroups,
  groupForEachWeek,
  mutable = false,
  onRemoveCounselorClick,
  onRemoveCamperClick,
}) => {
  const boyCount = campers.filter((camper) => camper.gender === "male").length;
  const girlCount = campers.filter((camper) => camper.gender === "female").length;

  function generateColor(index: number) {
    // Convert the index to an RGB value
    const r = (index * 30) % 256;
    const g = (index * 70) % 256;
    const b = (index * 110) % 256;

    // Return the RGB value as a string
    return `rgb(${r}, ${g}, ${b})`;
  }

  return (
    <div className="grid-item">
      <h5>
        {group.name} (Limit: {group.camperLimit})
      </h5>
      <h6>
        <Row className="counselor-row">
          <p className="counselor-title">Counselors:</p>
          {counselors.map((counselor) => (
            <div key={counselor.id}>
              {" "}
              {mutable ? (
                <Button variant="outline-danger" onClick={() => onRemoveCounselorClick(counselor.id)}>
                  🗑️ {counselor.firstName} {counselor.lastName}
                </Button>
              ) : (
                <u>
                  {counselor.firstName} {counselor.lastName},
                </u>
              )}
            </div>
          ))}
        </Row>
      </h6>
      <table>
        <thead>
          <tr>
            <th style={{ backgroundColor: "lightblue" }}>Boys: {boyCount}</th>
            <th style={{ backgroundColor: "pink" }}>Girls: {girlCount}</th>
          </tr>
        </thead>
      </table>
      <Table striped bordered size="sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Grade</th>
            <th>Gender</th>
            <th>Buddy ID</th>
            <th>Groups</th>
          </tr>
        </thead>
        <tbody>
          {campers.map((camper, index) => (
            <tr key={camper.id}>
              <td>
                {mutable && (
                  <Button variant="outline-danger" onClick={() => onRemoveCamperClick(camper)}>
                    🗑️
                  </Button>
                )}{" "}
                <p style={{ textAlign: "left" }}>
                  {index + 1}. {camper.firstName} {camper.lastName}
                </p>
              </td>
              <td>{camper.grade !== 0 ? camper.grade : "K"}</td>
              <td>{camper.gender}</td>
              <td>
                <p
                  style={{
                    backgroundColor: generateColor(findCamperBuddyGroupIndex(camperBuddyGroups, camper.camper_id) + 1),
                    color: "white",
                  }}
                >
                  {findCamperBuddyGroupIndex(camperBuddyGroups, camper.camper_id) + 1}
                </p>
              </td>
              <td>{groupForEachWeek[camper.camper_id]}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default GroupTable;
