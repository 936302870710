// Page for admin to filter and view camp roster

import axios from "axios";
import React from "react";
import { Container, Table, ProgressBar, Button, Form, Row, Col } from "react-bootstrap";
import { CamperRoster, Emergency_Contact, Camp_Week } from "./models/models";
import { dateTimeToDateInput } from "./utils/DateTimeUtil";
import { LoadingSpinner } from "./components/LoadingIcons";

export default function Roster() {
  const [isLoading, setisLoading] = React.useState(true);
  const [progress, setProgress] = React.useState(0);
  const [data, setData] = React.useState<CamperRoster[]>([]);
  const [numCampWeeks, setNumCampWeeks] = React.useState(0);
  const [rosterType, setRosterType] = React.useState("camper");
  const queryRef = React.useRef<any>(null);
  const sortRef = React.useRef<any>(null);
  const [fieldNames, setFieldNames] = React.useState<String[]>([]);
  const camperFieldNames = [
    "Child ID",
    "First Name",
    "Last Name",
    "Gender",
    "Date of Birth",
    "School",
    "Grade",
    "Shirt Size",
    "# of Shirts",
    "Doctor Name",
    "Doctor Phone",
    "Insurance",
    "Policy Holder",
    "Illnesses",
    "Allergies/Dietary Restrictions",
    "Medications",
    "Restricted Activities",
    "Medical Treatments",
    "Immunizations",
    "Tetanus Date",
    "Comments",
    "Guardian 1 First Name",
    "Guardian 1 Last Name",
    "Guardian 1 Phone #",
    "Guardian 1 Email",
    "Guardian 2 First Name",
    "Guardian 2 Last Name",
    "Guardian 2 Phone #",
    "Guardian 2 Email",
    "Address 1",
    "Address 2",
    "City",
    "State",
    "Zip Code",
    "Country",
    "Emergency Contact 1 First Name",
    "Emergency Contact 1 Last Name",
    "Emergency Contact 1 Phone #",
    "Emergency Contact 1 Relation",
    "Emergency Contact 1 Authorized",
    "Emergency Contact 2 First Name",
    "Emergency Contact 2 Last Name",
    "Emergency Contact 2 Phone #",
    "Emergency Contact 2 Relation",
    "Emergency Contact 2 Authorized",
    "Amount Paid",
    "Guardian's Credit",
  ];
  const parentFieldNames = [
    "First Name",
    "Last Name",
    "Phone #",
    "Email",
    "Guardian 2 First Name",
    "Guardian 2 Last Name",
    "Guardian 2 Phone #",
    "Guardian 2 Email",
    "Address 1",
    "Address 2",
    "City",
    "State",
    "Zip Code",
    "Country",
    "Credit",
  ];

  React.useEffect(() => {
    (async () => {
      await handleQueryChange({ target: { value: "registeredCurrentYear" } });
      await handleSortOptionChange({ target: { value: "firstName(A-Z)" } });
      await new Promise((r) => setTimeout(r, 500));
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (rosterType === "camper") {
        await axios.get(`${process.env.REACT_APP_API}/api/camp_weeks/getCamp_WeeksCurrentYear`).then((res) => {
          camperFieldNames.splice(9, 0, ...res.data.map((week: Camp_Week) => week.name));
          setFieldNames((f) => [...fieldNames]);
          setNumCampWeeks(res.data.length);
        });
        setFieldNames(camperFieldNames);
      } else if (rosterType === "parent") {
        setFieldNames(parentFieldNames);
      }
    })();
  }, [rosterType]);

  const handleQueryChange = async (e: { target: { value: string } }) => {
    let rosterType = "camper";
    setisLoading(true);
    setProgress(0);
    const query = e.target.value;
    let tempData: CamperRoster[] = [];
    if (query === "registeredCurrentYear") {
      // Get all campers registered for the current year
      await axios.get(process.env.REACT_APP_API + "api/campers/getCampersRosterCurrentYear").then((response) => {
        tempData = response.data;
      });
    } else if (query === "registeredPreviousYears") {
      // Get all campers registered for previous years
      await axios
        .get(process.env.REACT_APP_API + "api/campers/getCampersRosterRegisteredPreviousYears")
        .then((response) => {
          tempData = response.data;
        });
    } else if (query === "neverRegisteredCampers") {
      // Get all campers that have never been registered
      await axios.get(process.env.REACT_APP_API + "api/campers/getCampersRosterNeverRegistered").then((response) => {
        tempData = response.data;
      });
    } else if (query === "neverRegisteredParents") {
      // Get all parents that have never registered their campers
      rosterType = "parent";
      await axios.get(process.env.REACT_APP_API + "api/parents/getParentsRosterNeverRegistered").then((response) => {
        tempData = response.data;
      });
    } else if (query === "parentsNoCampers") {
      // Get all parents that have no campers
      rosterType = "parent";
      await axios.get(process.env.REACT_APP_API + "api/parents/getParentsRosterNoCampers").then((response) => {
        tempData = response.data;
      });
    } else if (query === "allCampers") {
      // Get all campers
      await axios.get(process.env.REACT_APP_API + "api/campers/getCampersRosterAll").then((response) => {
        tempData = response.data;
      });
    }
    if (rosterType === "camper") {
      const emergencyContacts = await axios.get(
        `${process.env.REACT_APP_API}/api/emergency_contacts/getEmergency_Contacts`
      );
      for (let i = 0; i < tempData.length; i++) {
        const parentID = tempData[i].parent_id;
        const emergencyContact = emergencyContacts.data.filter((e: Emergency_Contact) => e.user_id === parentID);
        tempData[i] = {
          ...tempData[i],
          emergencyContact1: { ...emergencyContact[0], authPickUp: Boolean(emergencyContact[0].authPickUp) },
          emergencyContact2: { ...emergencyContact[1], authPickUp: Boolean(emergencyContact[1].authPickUp) },
        };
        tempData[i] = await fetchMoreData(tempData[i]);
        setProgress(Math.trunc(((i + 1) / tempData.length) * 100));
      }
    }
    setRosterType(rosterType);
    setData(tempData);
    handleSortOptionChange({ target: { value: sortRef.current.value } });
    setisLoading(false);
  };

  const fetchMoreData = async (data: CamperRoster) => {
    if (queryRef.current.value === "registeredCurrentYear") {
      const res2 = await axios.get(
        `${process.env.REACT_APP_API}/api/registered_camper_weeks/getRegistered_Camper_WeeksWithCamp_WeeksByCamperID/${data.camper_id}`
      );
      data = {
        ...data,
        registeredWeeks: res2.data.map((week: any) => week.registered_camp_week_id),
      };
    } else {
      data = {
        ...data,
        registeredWeeks: new Array(numCampWeeks).fill(null),
      };
    }

    return data;
  };

  const handleSortOptionChange = async (e: { target: { value: string } }) => {
    const sortOption = e.target.value;
    if (sortOption === "firstName(A-Z)") {
      setData((d) => [...d.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))]);
    } else if (sortOption === "lastName(A-Z)") {
      setData((d) => [...d.sort((a, b) => (a.lastName > b.lastName ? 1 : -1))]);
    }
  };

  const handleDownload = () => {
    let csv = fieldNames.join(",") + "\n";
    if (rosterType === "camper") {
      data.forEach((e: CamperRoster) => {
        csv += `"${e.camper_id}","${e.firstName}","${e.lastName}","${e.gender}",\
"${dateTimeToDateInput(e.dob)}","${e.school}","${e.grade}","${e.shirtSize}","${e.numShirts}",\
${e.registeredWeeks.map((wk) => (wk ? 1 : 0)).join(",")},"${e.doctorName}","${e.doctorPhone}",\
"${e.insuranceCarrier}","${e.policyHolder}","${e.illnesses}","${e.allergies}","${e.medications}",\
"${e.restrictedActivities}","${e.medicalTreatments}","${e.immunizations}",\
"${dateTimeToDateInput(e.tetanusDate)}","${e.comments}","${e.parentFirstName}","${e.parentLastName}",\
"${e.phone}","${e.email}","${e.guardian2FirstName}","${e.guardian2LastName}","${e.guardian2Phone}",\
"${e.guardian2Email}","${e.addressLine1}","${e.addressLine2}","${e.city}","${e.state}","${e.zipCode}",\
"${e.country}","${e.emergencyContact1.firstName}","${e.emergencyContact1.lastName}",\
"${e.emergencyContact1.phone}","${e.emergencyContact1.relation}","${e.emergencyContact1.authPickUp ? 1 : 0}",\
"${e.emergencyContact2.firstName}","${e.emergencyContact2.lastName}","${e.emergencyContact2.phone}",\
"${e.emergencyContact2.relation}","${e.emergencyContact2.authPickUp ? "1" : "0"}","${e.paid}","${e.credit}"`;
        csv += "\n";
      });
    } else if (rosterType === "parent") {
      data.forEach((e: CamperRoster) => {
        csv += `"${e.firstName}","${e.lastName}","${e.phone}","${e.email}","${e.guardian2FirstName}",\
"${e.guardian2LastName}","${e.guardian2Phone}","${e.guardian2Email}","${e.addressLine1}","${e.addressLine2}",\
"${e.city}","${e.state}","${e.zipCode}","${e.country}","${e.credit}"`;
        csv += "\n";
      });
    }

    const csvData = new Blob([csv], { type: "text/csv" });
    const csvURL = URL.createObjectURL(csvData);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "roster.csv");
    tempLink.click();
  };

  return (
    <Container className="Schedule-Table">
      <Button variant="primary" className="backButton" href="#/admin">
        Back
      </Button>
      <h3>Roster</h3>
      <Form>
        <Row>
          <Form.Label>Filter: </Form.Label>
          <Form.Group as={Col} xs="auto" controlId="queryOption">
            <Form.Control ref={queryRef} as="select" style={{ textAlign: "center" }} onChange={handleQueryChange}>
              <option value="registeredCurrentYear">Registered (Current Year)</option>
              <option value="registeredPreviousYears">Registered (Previous Years)</option>
              <option value="neverRegisteredCampers">Never Registered (Campers)</option>
              <option value="neverRegisteredParents">Never Registered (Parents)</option>
              <option value="parentsNoCampers">Parents with No Campers</option>
              <option value="allCampers">All Campers</option>
            </Form.Control>
          </Form.Group>
        </Row>
        <Row>
          <Form.Label>Sort by: </Form.Label>
          <Form.Group as={Col} xs="auto" controlId="sortOption">
            <Form.Control ref={sortRef} as="select" style={{ textAlign: "center" }} onChange={handleSortOptionChange}>
              <option value="firstName(A-Z)">First Name (A-Z)</option>
              <option value="lastName(A-Z)">Last Name (A-Z)</option>
              {/* <option value="registered(mostRecent)">Registered (Most Recent)</option> */}
            </Form.Control>
          </Form.Group>
        </Row>
      </Form>
      {isLoading ? (
        <div>
          <LoadingSpinner animation="border" />
          <ProgressBar now={progress} label={`${progress}%`} />
        </div>
      ) : (
        <div>
          <Button style={{}} onClick={handleDownload}>
            Download Roster (CSV File)
          </Button>
          <Table striped bordered size="sm" style={{ marginTop: 30 }}>
            <thead>
              <tr>
                {fieldNames.map((field, index) => (
                  <th key={index}>{field}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rosterType === "camper" &&
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.camper_id}</td>
                    <td>{item.firstName}</td>
                    <td>{item.lastName}</td>
                    <td>{item.gender}</td>
                    <td>{dateTimeToDateInput(item.dob)}</td>
                    <td>{item.school}</td>
                    <td>{item.grade}</td>
                    <td>{item.shirtSize}</td>
                    <td>{item.numShirts}</td>
                    {item.registeredWeeks.map((wk, index) => (wk ? <td key={index}>1</td> : <td key={index}>0</td>))}
                    <td>{item.doctorName}</td>
                    <td>{item.doctorPhone}</td>
                    <td>{item.insuranceCarrier}</td>
                    <td>{item.policyHolder}</td>
                    <td>{item.illnesses}</td>
                    <td>{item.allergies}</td>
                    <td>{item.medications}</td>
                    <td>{item.restrictedActivities}</td>
                    <td>{item.medicalTreatments}</td>
                    <td>{item.immunizations}</td>
                    <td>{dateTimeToDateInput(item.tetanusDate)}</td>
                    <td>{item.comments}</td>
                    <td>{item.parentFirstName}</td>
                    <td>{item.parentLastName}</td>
                    <td>{item.phone}</td>
                    <td>{item.email}</td>
                    <td>{item.guardian2FirstName}</td>
                    <td>{item.guardian2LastName}</td>
                    <td>{item.guardian2Phone}</td>
                    <td>{item.guardian2Email}</td>
                    <td>{item.addressLine1}</td>
                    <td>{item.addressLine2}</td>
                    <td>{item.city}</td>
                    <td>{item.state}</td>
                    <td>{item.zipCode}</td>
                    <td>{item.country}</td>
                    <td>{item.emergencyContact1.firstName}</td>
                    <td>{item.emergencyContact1.lastName}</td>
                    <td>{item.emergencyContact1.phone}</td>
                    <td>{item.emergencyContact1.relation}</td>
                    <td>{item.emergencyContact1.authPickUp ? 1 : 0}</td>
                    <td>{item.emergencyContact2.firstName}</td>
                    <td>{item.emergencyContact2.lastName}</td>
                    <td>{item.emergencyContact2.phone}</td>
                    <td>{item.emergencyContact2.relation}</td>
                    <td>{item.emergencyContact2.authPickUp ? 1 : 0}</td>
                    <td>{item.paid}</td>
                    <td>{item.credit}</td>
                  </tr>
                ))}
              {rosterType === "parent" &&
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.firstName}</td>
                    <td>{item.lastName}</td>
                    <td>{item.phone}</td>
                    <td>{item.email}</td>
                    <td>{item.guardian2FirstName}</td>
                    <td>{item.guardian2LastName}</td>
                    <td>{item.guardian2Phone}</td>
                    <td>{item.guardian2Email}</td>
                    <td>{item.addressLine1}</td>
                    <td>{item.addressLine2}</td>
                    <td>{item.city}</td>
                    <td>{item.state}</td>
                    <td>{item.zipCode}</td>
                    <td>{item.country}</td>
                    <td>{item.credit}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      )}
    </Container>
  );
}
